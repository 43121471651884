$(document).ready(function() {
  $('.czt-jobs__item__title').on('click', function(event) {
    event.preventDefault();

    var title = $(this);
    var desc = title.next();

    title.toggleClass('czt-jobs__item__title--active');
    desc.slideToggle(400);

    if (title.hasClass('czt-jobs__item__title--active')) {
      $('html, body').animate({
        scrollTop: (title.offset().top - $('#pageheader').outerHeight(true))
      });
    }
  });
});
