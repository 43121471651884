(function($){

  var home_visual = false;
  var pageheader_height = 150;
  var scrolled_count = 0;
  var scrolled_top = 0;
  var scrolled_down = false;
  var window_height = 0;
  var window_width = 0;

  // Set window height
  function get_windowSize() {
    window_height = window.innerHeight;
    window_width = window.innerWidth;
  }

  // Set height for care postrow columns
  function care_postRowColumnHeight() {
    if($('.care-sendrow').length > 0) {
      var sendrow_form_height = $('.care-sendrow .sendrow-form').height();
      var sendrow_post_height = $('.care-sendrow .sendrow-post').height();
      var sendrow_or_height = Math.max(sendrow_form_height, sendrow_post_height);

      $('.care-sendrow .sendrow-or').height(sendrow_or_height);
    }
  }

  // Make the scroll arrow stick to the .home-background-visual if traveled a certain distance
  function stick_homeVisualScroll() {
    if(!$('.home-visual-scroll').hasClass('scroll-stick') && (scrolled_top + window_height) >= $('.home-background-visual').height()) {
      $('.home-visual-scroll').addClass('scroll-stick');
    } else if($('.home-visual-scroll').hasClass('scroll-stick') && (scrolled_top + window_height) < $('.home-background-visual').height()) {
      $('.home-visual-scroll').removeClass('scroll-stick');
    }
  }

  $(document).ready(function() {
    // Toggle header search
    $('.toolbelt-search').click(function() {
      $(this).toggleClass('toolbelt-clicked');
      $('#pageheader .header-search').toggleClass('search-visible');

      if(home_visual) {
        $('.home-visual.visual-top').toggleClass('visual-search');
      }
    });

    // Set home_visual = true if it exists
    if($('.home-background-visual').length > 0) {
      home_visual = true;
    }

    // 2 scroll functions, first until the lower end of home-background-visual, then the height of the second visual part
    $('.home-visual-scroll').click(function() {
      $('html, body').animate({ scrollTop: $('.home-background-visual').height() }, 1000, 'easeInOutQuad');
    });

    // Mobile menu
    $('.mainmenu-mobile-expander').click(function() {
      $('.mainmenu-mobile-wrapper').toggleClass('wrapper-visible');
      $('#pageheader-smallscreen').toggleClass('mobile-menu-visible');
    });

    // Accordion
    $('.czt-accordion .item__headline').on('click', function() {
      var headline = $(this);
      var item = headline.parent();

      if (item.hasClass('czt-accordion__item--active')) {
        headline.next().slideUp(400);
        item.removeClass('czt-accordion__item--active');

        return;
      }

      headline.next().slideDown(400);
      item.addClass('czt-accordion__item--active');
    });
  });

  $(window).load(function() {
    pageheader_height = $('#pageheader').outerHeight(true);

    get_windowSize();

    // Set height for care postrow columns
    care_postRowColumnHeight();

    // Make the scroll arrow stick to the .home-background-visual if traveled a certain distance
    stick_homeVisualScroll();
  });

  $(window).resize(function() {
    get_windowSize();
    pageheader_height = $('#pageheader').outerHeight(true);

    // Set height for care postrow columns
    care_postRowColumnHeight();
  });

  $(window).scroll(function() {
    scrolled_top = $(window).scrollTop();

    // Make the scroll arrow stick to the .home-background-visual if traveled a certain distance
    if(home_visual) {
      stick_homeVisualScroll();

      if(scrolled_top > 30) {
        scrolled_down = true;
      }

      if(scrolled_down && !$('.home-visual-scroll').hasClass('scroll-down')) {
        $('.home-visual-scroll').addClass('scroll-down');
      }
    }

    // Reset the scrolled_count if went to top
    if(scrolled_top < 60 && scrolled_count > 0) {
      scrolled_count = 0;
    }

    // Sticky pageheader
    if(!home_visual && window_width > 768 && scrolled_top > pageheader_height && !$('#pageheader').hasClass('pageheader-sticky')) {
      $('#pageheader').addClass('pageheader-sticky');

      setTimeout(function() {
        $('#pageheader .sticky-container').addClass('sticky-slidein');
      }, 10);
    } else if(scrolled_top <= 20 && $('#pageheader').hasClass('pageheader-sticky')) {
      $('#pageheader').removeClass('pageheader-sticky');
       $('#pageheader .sticky-container').removeClass('sticky-slidein');
    }
  });

})(window.jQuery);
